import styled, { css } from 'styled-components';

export const TestimonialsSection = styled.section(
  ({ theme }) => css`
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
  `
);

export const TestimonialsWrapper = styled.div`
  width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;
`;

export const TestimonialsContent = styled.div`
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Space between the grid items */
  display: grid;
  width: 100%;
  z-index: 2;
  padding: 50px 0 50px 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 30px 0;
  }
`;


export const TestimonialsLogoContent = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 7px 9px -7px #010101bd;
`;

export const TestimonialsTitle = styled.h2(
  ({ theme }) => css`
    color: #ffffff;
    font-family: ${theme.typography.fonts.secondary};
    font-size: ${theme.typography.sizes.extraExtraLarge};
    font-weight: ${theme.typography.weights.medium};
    text-transform: uppercase;
    line-height: 1.714;
    text-align: center;
    width: 100%;
  `
);

export const TestimonialsLogo = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 10px;
  cursor: pointer;
`;

export const TestimonialsDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary['dark-90']};
    margin: 0 0 30px;
    font-size: ${theme.typography.sizes.small};
    line-height: 1.114;
    text-align: justify;
    overflow: hidden;
    white-space: pre-line;
  `
);
