import { useTranslation } from "react-i18next";
import {
  TestimonialsContent,
  TestimonialsDescription,
  TestimonialsLogo,
  TestimonialsLogoContent,
  TestimonialsSection,
  TestimonialsTitle,
  TestimonialsWrapper,
} from "./Testimonials.styled";
import Migrapreneur from '../../../static/partners/migrapreneur.png';

const external_link: string = "https://migrapreneur.notion.site/Migrapreneur-8c30282cc5b44e6d870b12b64c255596"

const Testimonials = () => {
  const { t } = useTranslation();
  return (
    <TestimonialsSection id="testimonials">
      <TestimonialsWrapper>
      <TestimonialsTitle>{t("testimonials_title")}</TestimonialsTitle>
        <TestimonialsContent>
          <TestimonialsLogoContent>
            <TestimonialsLogo
              src={Migrapreneur}
              alt="testimonial-logo"
              onClick={() =>
                window.open(
                  external_link
                )
              }
            />
            <TestimonialsDescription>
              <strong>{t("testimonials_author_01")}</strong>
              <br></br>
              <small>{t("testimonials_org_01")}</small>
              <br></br>
              <br></br>
              <i>{t("testimonials_desc_01")}</i>
              {/* This needs to be a block of testimonials --> */}
            </TestimonialsDescription>
          </TestimonialsLogoContent>
        </TestimonialsContent>
      </TestimonialsWrapper>
    </TestimonialsSection>
  );
};

export default Testimonials;
