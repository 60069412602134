import React from "react";
import Contact from "../components/containers/Contact/Contact.component";
import Faqs from "../components/containers/Faqs/Faqs.component";
import Features from "../components/containers/Features/Features.component";
import HowItWorks from "../components/containers/HowItWorks/HowItWorks.component";
import Layout from "../components/containers/Layout.component";
import Pricing from "../components/containers/Pricing/Pricing.component";
import Slider from "../components/containers/Slider/Slider.component";
import Team from "../components/containers/Team/Team.component";
import Hero from "../components/organisms/Hero/Hero.component";
import Carrousel from "../components/containers/HowItWorks/Carousel/CarouselQR";
import Testimonials from "../components/containers/Testimonials/Testimonials.component";
import {Partners} from "../components/containers/Partners/Partners";

const Home = () => {
  return (
    <Layout>
      <Hero />
      <Partners/>
 {/*  <HowItWorks /> for now not needed */}
      <Features />
      <Carrousel/>
      <Slider />
      <Testimonials />
      <Pricing />
      <Team />
      <Faqs />
      <Contact />
    </Layout>
  );
};

export default Home;
