import React from "react";
import {
  HeroSection,
  Overlay,
  Content,
  Illustration,
  HeroTitle,
  HeroDescription,
  HeroParagraph,
  HeroContainer,
  Button,
  RightSideButton,
  HeroItalicParagraph,
  Wave
} from "./Hero.styled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const tendbeApp = "https://app.tendbe.com/";

  const { t } = useTranslation();  

  return (
    <>
      <HeroSection>
        <Overlay />
        <Wave />
        <HeroContainer>
          <Content>
            <HeroTitle>{t("hero.part1")}</HeroTitle>
            <HeroDescription>
              <HeroItalicParagraph>
                {t("hero.part2")}
              </HeroItalicParagraph>
              <HeroParagraph>
                {t("hero.part3")}                
              </HeroParagraph>
              <RightSideButton>
                <Button href={tendbeApp} target="_blank"> {t("hero.part4")} </Button>
              </RightSideButton>
            </HeroDescription>
          </Content>
        </HeroContainer>

        <Illustration />
      </HeroSection>
    </>
  );
};

export default Hero;
