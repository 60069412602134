export const pricing = (translator) => [
  {
    title: "free",
    list: [
      { text: "10 polls per channel", value: true },
      { text: "Maximum of 3 channels", value: true },
      { text: "QR offline voting", value: true },
      { text: "Unbrandend QR codes", value: true },
      { text: "1 data report per channel", value: true },
    ],
  },
  {
    title: "premium",
    list: [
      { text: "Unlimited poll creation", value: true },
      { text: "Unlimited channels", value: true },
      { text: "Branded QR codes", value: true },
      { text: "Reward system for clients", value: true },
      { text: "Unlimited data reports", value: true },
      { text: "Raw data export", value: true },
      { text: "Voters can comment on polls", value: true },
    ],
  },
];
